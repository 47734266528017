<template>
    <div class="forgotPassword">
        <rx-navBar title="忘记密码"></rx-navBar>
        <div class="bindPhone_title">
            <div>忘记密码</div>
        </div>
        <div class="inputPhone">
            <!-- 使用三元运算判断手机号是否有输入，前面的圆点点亮-->
            <div :class="[moblieValue == '' ? 'grayCircle':'colorCircle']" ref="phoneDot"></div>
            <div class="text">手机账号</div>
            <div class="line"></div>
            <!-- 失焦后检查手机号是否为合规的手机号码-->
            <input  v-model="moblieValue" type="text" @blur="checkMoblie()"  placeholder="请输入手机号"  maxlength="11" onkeyup="this.value=this.value.replace(/\D/g,'')"/>
        </div>
        <div class="inputCode" v-if="verifyWay">
            <div :class="[code == '' ? 'grayCircle':'colorCircle']" ref="codeDot"></div>
            <div class="text">验证码</div>
            <div class="line"></div>
            <input type="text" maxlength="6" v-model="code" placeholder="请输入验证码" onkeyup="this.value=this.value.replace(/\D/g,'')" />
            <button class="getCode" v-show="show"   @click="getCode()">获取验证码</button>
            <button v-show="!show" disabled class="count">{{count}} S后重发</button>
        </div>
        <div class="inputIdCard" v-if="!verifyWay">
            <div :class="[idcard == '' ? 'grayCircle':'colorCircle']" ref="codeDot"></div>
            <div class="text">身份证</div>
            <div class="line"></div>
            <input type="text" maxlength="18" v-model="idcard" placeholder="请输入身份证号"  />
        </div>        
        <div class="inputPassword">
            <!--三目运算符，判断输入是否已输入数值-->
            <div :class="[newPwd == '' ? 'grayCircle':'colorCircle']"></div>
            <div class="text">新密码</div>
            <div class="line"></div>
            <input :type="pwdType"  v-model="newPwd"  placeholder="请输入新密码"  />
            <!--  点击触发密码的显示和隐藏，初始设置为密码不可见-->
            <img v-show="hide" class="eye" src="../../assets/images/hidden.png" @click="showhide()">
            <img   v-show="!hide" class="eye" src="../../assets/images/show.png" @click="showhide()">
        </div>
        <div class="inputPasswordTwo">
            <!--三目运算符，判断输入是否已输入数值-->
            <div :class="[pwdTypeTwo == '' ? 'grayCircle':'colorCircle']"></div>
            <div class="text">确认密码</div>
            <div class="line"></div>
            <input :type="TypeTwo"  v-model="pwdTypeTwo" @blur="checkPwd()" placeholder="确认密码"  />
            <!--  点击触发密码的显示和隐藏，初始设置为密码不可见-->
            <img v-show="hideTwo" class="eye" src="../../assets/images/hidden.png" @click="showhideTwo()">
            <img   v-show="!hideTwo" class="eye" src="../../assets/images/show.png" @click="showhideTwo()">
        </div>

        <div class="inputPasswordThree">
            <div class="idcardVerify" @click="idcardVerify(0)" v-if="verifyWay">无法获取验证码?</div>
            <div class="idcardVerify" @click="idcardVerify(1)" v-if="!verifyWay">验证码找回</div>
        </div>        
        <!-- 所有输入框有值才能点击确定按钮-->
        <div class="btnDiv">
        <button :disabled="!moblieValue || (!code&& !idcard) || !newPwd || !pwdTypeTwo  "
                :class="[moblieValue=='' || (code==''&&idcard=='') || newPwd == '' || pwdTypeTwo == '' 
                ? 'bind':'binding']"  type="submit" @click="savePassword">
            确定
        </button>
        </div>
    </div>
</template>

<script>
    import {NavBar, Toast} from 'vant';
    import {responseUtil} from "../../libs/rongxunUtil";
    import {forgetPassword, forgetPasswordSendCode,certificateCodeVerification } from "../../getData/getData";
    import rxNavBar from '../../components/rongxun/rx-navBar/navBar';

    export default {

        components : {
            [NavBar.name]: NavBar,
            [Toast.name]: Toast,
            [rxNavBar.name] : rxNavBar,
        },
        name: "forgotPassword",
        data(){
            return{
                msgCode : '0',
                // disabled:true,  //获取验证码按钮为可点击状态
                moblieValue:"",  //手机输入框状态
                code:"",    //密码输入框状态
                bindShow:true, //确认按钮为不可点击状态
                show: true, //获取验证码按钮为显示状态
                count: '',   //倒计时秒数
                timer: null,    //倒计时状态
                newPwd:"",//新密码
                hide:true,//输入框眼睛为闭眼状态
                hideTwo:true,
                pwdType:"password",//密码输入框的文本类型
                TypeTwo:"password",
                pwdTypeTwo:"",//新密码确认
                verifyWay:true,              //验证方式  true为验证码 false为身份证
                idcard:'',                   //身份证号
            }
        },
        methods:{
            idcardVerify(e){
                if(e==0){
                   this.verifyWay=false,
                   this.idcard=''
                }else if(e==1){
                   this.verifyWay=true
                }
            },

            //手机号验证
            checkMoblie() {
                var re = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                 if (!re.test( this.moblieValue)) { //如果手机号码符合，提示消息不会弹出
                     responseUtil.alertMsg(this,'手机号格式不正确')
                     this.msgCode = '0'
                     // Toast('手机号格式不正确');
                }else{
                     this.msgCode='1'
                 }
            },
            //验证码合计时和发送接口
            getCode(){
                if(this.moblieValue==''){
                    responseUtil.alertMsg(this,'请输入手机号')
                }
                else if(this.msgCode=='0'){
                    this.checkMoblie()
                }else{
                    const TIME_COUNT = 60; //倒计时总秒数为60秒
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                    let that = this
                    let initData = {}
                    initData.mobile = that.moblieValue
                    forgetPasswordSendCode(initData).then(function (response) {
                        responseUtil.dealResponse(that,response,() =>{
                            console.log(response)
                        })
                    })
                }

            },
            showhide(){
                if (this.pwdType== "password"){
                    this.pwdType = "text"       //如果点击眼睛，输入框属性为password时，改为text
                    this.hide = false
                }else {
                    this.pwdType = "password"
                    this.hide = true
                }
            },
            showhideTwo(){
                if (this.TypeTwo== "password"){
                    this.TypeTwo = "text"       //如果点击眼睛，输入框属性为password时，改为text
                    this.hideTwo = false
                }else {
                    this.TypeTwo = "password"
                    this.hideTwo = true
                }
            },
            checkPwd(){
                if(this.pwdTypeTwo != this.newPwd){
                    responseUtil.alertMsg(this,'两次输入密码不一致');
                    return false
                }else{
                    return true
                }
            },
            //修改密码接口
            savePassword(){
                let that = this
                var status=that.checkPwd()
                if(!status){
                   return  
                }
                if(that.verifyWay){
                let initData = {}
                initData.mobile = that.moblieValue
                initData.verifyCode =   that.code
                initData.newPassword = that.newPwd
                initData.newPasswordConfirm = that.pwdTypeTwo
                forgetPassword(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,() =>{
                        console.log(response)
                        if(response.data.code=='0'){
                            responseUtil.alertMsg(that,'修改成功，请登录')
                            that.goLogin()
                        }
                    })
                })
                }else{
                let queryData = {}
                queryData.mobile = that.moblieValue
                queryData.certificateCode =   that.idcard
                queryData.password = that.newPwd
                certificateCodeVerification(queryData).then(function (response) {
                    responseUtil.dealResponse(that,response,() =>{
                        console.log(response)
                        if(response.data.code=='0'){
                            responseUtil.alertMsg(that,'修改成功，请登录')
                            that.goLogin()
                        }
                    })
                })
                }

            },
            //保存后返回主页面
            goLogin(){
                this.$router.push({
                    name : 'login'
                })
            }
        }
    }
</script>

<style scoped>
    /*父级div*/
    .forgotPassword{
        width: 100%;
        padding-bottom: 50px;
    }

    /*两次密码输入错误信息提示*/
    .errorPwdDiv{
        float: left;
        height: 1rem;
        margin-top: 0.2rem;
        width: 92%;
        margin-left: 4%;
    }
    .errorPwd{
        height: 0.5rem;
        color: red;
        width: 2.5rem;
        float: right;
        font-size: 10px;
    }
    .errorPhoneDiv{
        float: left;
        height: 0.35rem;
        margin-top: 2px;
        width: 92%;
        margin-left: 4%;
    }
    .errorPhone{
        height: 0.3rem;
        color: red;
        width: 1.95rem;
        float: right;
        font-size: 10px;
    }
    /*手机绑定的页面顶部的div样式*/
    .bindPhone_title{
        background: url("../../assets/images/head-quotation-mark.png") left no-repeat;
        background-size: 27% 100%;
        width: 6rem;height:2rem;
        border: 1px solid #f8f8f8;
        margin-top: 1.2rem;
        margin-bottom: 0.5rem;

    }
    .bindPhone_title div{
        height:1rem;
        width: 4rem;
        margin-top: 0.95rem;
        font-size: 25px;
        font-weight: bold;
        margin-left: 0.44rem;
    }

    /*圆点样式*/
    .colorCircle{
        border-radius: 50%; /*设置圆点与其后面的内容水平显示*/
        width: 0.22rem;          /*设置圆点的宽、高、背景颜色，再设置圆点半径所占百分比*/
        height: 0.22rem;
        background-color: #ff5D3B;
        float: left;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        margin-top: 0.65rem;
    }
    .grayCircle{
        width: 0.22rem;
        height: 0.22rem;
        background-color: #C7C7C7;
        border-radius: 50%;
        float: left;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        margin-top: 0.65rem;
    }
    /*竖线样式*/
    .line {
        height: 0.33rem;
        float: left;
        border: #D8D8D8 solid 0.5px;
        margin-left: 0.23rem;
        margin-right: 0.22rem;
        margin-top: 0.6rem
    }
    /*label样式*/
    .text{
        font-size: 15px;
        float: left;
        margin-top: 0.45rem;
        font-weight: bold;
    }
    /*验证码输入框Div*/
    .inputCode {
        float: left;
        height:1.4rem;
        width: 92%;
        margin-left: 4%;
        border-radius: 0.15rem;/*圆角*/
        background: white;
    }
    /*身份证输入框 */
    .inputIdCard {
        float: left;
        height:1.4rem;
        width: 92%;
        margin-left: 4%;
        border-radius: 0.15rem;/*圆角*/
        background: white;
    }    
    /*手机号码输入框*/
    .inputPhone input {
        float: left;
        width:4rem;
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 0.5rem;
    }
    /*手机号码输入框DIV*/
    .inputPhone{
        float: left;
        height:1.4rem;
        width: 92%;
        margin-left: 4%;
        border-radius: 0.15rem;/*圆角*/
        background: white;
        margin-bottom: 15px;
    }

    /*验证码输入框*/
    .inputCode input {
        float: left;
        width:145px;
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 0.5rem;
    }
    .inputIdCard input {
        float: left;
        width:165px;
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 0.5rem;
    }
    /*属性placeholder样式*/
    input::-webkit-input-placeholder{
        color: #D8D8D8;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        text-align: left;
    }
    /*获取验证码按钮*/
    .getCode{
        float: left;
        color:  #FF5D3B;
        border-radius: 0.2rem;
        font-size: 13px;
        height: 0.85rem;
        width: 2.3rem;
        border: 0.02rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        margin-top: 0.289rem;
    }
    /*重发验证码时间按钮*/
    .count{
        float: left;
        color:  #FF5D3B;
        border-radius: 0.2rem;
        font-size: 13px;
        height: 0.85rem;
        width: 2.3rem;
        border: 0.02rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        margin-top: 0.289rem;
    }
    /*显示和隐藏图标样式*/
    .eye{
        height: 0.25rem;
        width: 0.55rem;
        margin-top: 0.58rem;
    }
    /*x新密码输入框样式*/
    .inputPassword{
        float: left;
        width: 92%;
        margin-left: 4%;
        margin-top: 0.35rem;
        font-size:16px;
        background:white;
        height: 1.35rem;
        border-radius: 0.15rem;/*圆角*/
    }
    .inputPassword input {
        width: 210px;
        float: left;
        margin-top: 0.485rem;
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    /*确认密码输入框样式*/
    .inputPasswordTwo{
        float: left;
        width: 92%;
        margin-left: 4%;
        margin-top: 0.35rem;
        font-size:16px;
        background:white;
        /*margin-top:1rem;*/
        height: 1.35rem;
        border-radius: 0.15rem;/*圆角*/
    }

    .inputPasswordTwo input {
        width: 196.5px;
        float: left;
        margin-top: 0.485rem;
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    .inputPasswordTwo:after{
        content: '';
        display: block;
        clear: both;
    }
    .inputPasswordThree{
        float: left;
        width: 92%;
        margin-left: 4%;
        margin-top: 0.35rem;
        font-size:16px;
        /*margin-top:1rem;*/
        height: 1.35rem;
        border-radius: 0.15rem;/*圆角*/
    }    
    .btnDiv{
        /*border: 1px solid red;*/
        content: '';
        display: block;
        clear: both;
        height: 100%;
    }
    /*按钮不可点击绑定样式*/
    .bind{
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 4%;
        height: 1.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        background:rgba(184, 184, 184, 0.2);
        margin-top: 3.5rem;
    }
    /*可以点击绑定的样式*/
    .binding{
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 3%;
        height: 1.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        margin-top: 3.5rem;
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;/*渐变色*/
    }

    .idcardVerify{
        font-family: PingFangSC-Semibold;
        font-size:13px;
        float:right;
        position:relative;
        color: steelblue;
        top: 0.4rem;
        right:0.5rem;
        text-align:right;
    }

</style>
